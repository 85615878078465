const brandColors = {
  primary: '#1B17FF',
  secondary: '#011E68',
};

const sidebarColors = {
  backgroundColor: '#F4EDE6',
  color: '#15171A',
  dotfileLogo: '#15171A',
};

const linkColors = {
  color: '#0176FF',
};

export const tokens = {
  brand: brandColors,
  sidebar: sidebarColors,
  link: linkColors,
};
